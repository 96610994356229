import React from 'react';
import { connect } from 'react-redux';
import { getFeatureFlags } from 'selectors/featureFlags';
import { NeonIcon } from '@ps-refarch-ux/neon';

import s from './styles.scss';

function StudentHelpCenterLink({
  featureFlags: { featureNavianceStudentStudentHelpCenterLink = false } = {},
}) {
  return (
    featureNavianceStudentStudentHelpCenterLink && (
      <a
        href="https://ps.powerschool-docs.com/naviance-student/"
        className={s.studentHelpCenterLink}
        title={'Student Help Center'}
        target="_blank"
        rel="nofollow external noopener noreferrer"
      >
        <NeonIcon dataIcon="circle-help" />
      </a>
    )
  );
}

export default connect((state) => ({
  featureFlags: getFeatureFlags(state),
}))(StudentHelpCenterLink);
