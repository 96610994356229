// @flow
import jwtDecode from 'jwt-decode';
import ss from 'utils/sessionStorage';
import ls from 'utils/localStorage';
import Raven from 'raven-js';

type SessionData = {
  expDate: Date,
  exp: number,
  highSchoolAlias: string,
  iat: number,
  permissions: Object,
  role: string,
  username: string,
  id: number,
  renewPwd: boolean,
  grade: string,
  highSchoolId: string,
  loginUser: {
    userId: string,
    role: string,
    userJobFunction: string,
  },
  isLocalAuth: boolean,
  userJobFunction: string,
};

type Session = {
  jwt: ?string,
  data: SessionData,
  register: (string) => SessionData,
  registerCookie: (void) => void,
  isValid: (void) => boolean,
  clear: (?boolean) => void,
  needsNewPassword: (void) => boolean,
  getSessionCookie: (?string) => string,
  clearJWT: (void) => void,
};

// default cookie name
const COOKIE_NAME = 'SESSION';

// jwt token
let jwt: ?string = ss.getItem('jwt') ? ss.getItem('jwt') : ls.getItem('deepLinkingAuthorizedToken');

// decoded jwt token data
let sessionData: ?SessionData = jwt ? jwtDecode(jwt) : null;

// get cookie by name
function getCookie(cookieName: string = COOKIE_NAME) {
  const b = document.cookie.match(`(^|;)\\s*${cookieName}\\s*=\\s*([^;]+)`);
  return b ? b.pop() : '';
}

function setupSentryUserContext(userSession) {
  if (userSession) {
    const { id, role, parent, counselor } = userSession;
    if (parent) {
      Raven.setUserContext({ id, parentId: parent.id, role: 'Parent' });
    }
    if (counselor) {
      Raven.setUserContext({ id, counselorId: counselor.id, role: 'Counselor' });
    }
    if (!parent && !counselor) {
      Raven.setUserContext({ id, role });
    }
  }
}

function registerSession(token: string): ?SessionData {
  if (typeof token !== 'string') {
    return;
  }

  jwt = token;
  sessionData = jwtDecode(token);

  // get the session duration in seconds
  // obtained by subtracting the token "issued at time" form the session expiration time computed on the server
  const sessionDuration = parseInt(sessionData.exp - sessionData.iat - 5, 10) * 1000;
  sessionData.expDate = new Date(parseInt(sessionData.iat, 10) * 1000 + sessionDuration);
  if (!ls.getItem('deepLinkingAuthorizedToken')) {
    ss.setItem('jwt', token);
  } else {
    ls.setItem('deepLinkingAuthorizedToken', token);
  }
  setupSentryUserContext(sessionData);
  return sessionData; // eslint-disable-line consistent-return
}

function registerCookie() {
  // save cookie after login
  // used for impersonating to force jwt creation
  ss.setItem('fcms-cookie', getCookie());
}

function clearJWT() {
  ss.removeItem('jwt');
}

// used to clear translated phrases stored in localstorage when user log's out
function clearTranslatedTextInLocalStorage() {
  Object.keys(ls).forEach((key) => {
    if (key.includes('TranslatedPhrase')) {
      ls.removeItem(key);
    }
  });
}

function logout(cleanSchool: boolean = false) {
  jwt = null;
  sessionData = null;
  clearJWT();
  clearTranslatedTextInLocalStorage();
  ss.removeItem('fcms-cookie');
  if (cleanSchool) {
    ls.removeItem('hsid');
  }
  if (ls.getItem('deepLinkingAuthorizedToken')) {
    ls.removeItem('deepLinkingAuthorizedToken');
  }
  if (sessionStorage.getItem('headed2Token')) {
    sessionStorage.removeItem('headed2Token');
  }
  if (sessionStorage.getItem('stateCode')) {
    sessionStorage.removeItem('stateCode');
  }
}

function isCookieValid(): boolean {
  const sessionStorageCookie = ss.getItem('fcms-cookie');
  const appCookie = getCookie();

  return !sessionStorageCookie || !appCookie || sessionStorageCookie === appCookie;
}

function isAuthenticated(): boolean {
  return !!jwt && isCookieValid() && !!sessionData && sessionData.expDate > new Date();
}

/**
 * If renewPwd is set to true in JWT token then let the system know
 * This means user should change password immediately
 */
function needsNewPassword(): boolean {
  if (jwt && sessionData && sessionData.renewPwd && sessionData.isLocalAuth) {
    const { loginUser } = sessionData;
    return loginUser
      ? loginUser.userId === sessionData.id && loginUser.role === sessionData.role
      : true;
  }
  return false;
}

// check for authenticity of the token in local storage
if (jwt) {
  registerSession(jwt);

  // ok we used to have a jwt token, but is it still valid?
  if (!isAuthenticated()) {
    logout();
  }
}

const session: Session = Object.defineProperties(
  {},
  ({
    jwt: {
      get: () => jwt,
      enumerable: false,
    },

    data: {
      get: () => sessionData,
    },

    register: {
      value: registerSession,
    },

    registerCookie: {
      value: registerCookie,
    },

    isValid: {
      value: isAuthenticated,
    },

    clear: {
      value: logout,
    },

    isCookieValid: {
      value: isCookieValid,
    },

    needsNewPassword: {
      value: needsNewPassword,
    },

    getSessionCookie: {
      value: getCookie,
    },

    clearJWT: {
      value: clearJWT,
    },
  }: Object) // https://github.com/facebook/flow/issues/285
);

export default session;
