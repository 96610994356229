import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from 'components/App';
import store from 'store';

const renderApp = (Component) =>
  render(
    <Provider store={store}>
      <Router>
        <AppContainer>
          <Component />
        </AppContainer>
      </Router>
    </Provider>,
    document.getElementById('root')
  );

renderApp(App);

// Hot Module Replacement API
/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('components/App', () => renderApp(App));
}
