import { getHash } from './sha3';
import { ROLES } from '../selectors/auth';

export function getUserDataForAnalytics(currentUser) {
  let userId;
  let userType;
  let userRole;
  let identity;

  if (currentUser.loginUser) {
    userId = currentUser.loginUser.userId;
    userType = currentUser.loginUser.userJobFunction;
    userRole = currentUser.loginUser.role;
  } else {
    userId = currentUser.id;
    userType = currentUser.userJobFunction;
    userRole = currentUser.role;
  }

  switch (userRole) {
    case ROLES.student:
    case ROLES.alumni:
      // We are not allowed to send student ids to 3rd party. So, hashing them.
      userId = getHash(`${userId}`);
      identity = `student:${userId}`;
      break;
    case ROLES.parent:
      identity = `parent:${userId}`;
      break;
    case ROLES.counselor:
    case ROLES.schoolUser:
    case ROLES.districUser:
      identity = `staff:${userId}`;
      break;
    default:
  }

  return { userId, userType, userRole, identity };
}
