import * as config from 'config/rewritable-config';

export const cpuiUrl = config.CPUI_URL;
export const careers2UiUrl = config.CAREERS2_UI_URL;
export const host = config.API_HOST;
export const inProductMessagingUrl = config.IN_PRODUCT_MESSAGING_URL;
export const hubsUiUrl = config.HUBSUI_URL;
export const inlineManualUrl = config.INLINE_MANUAL_URL;
export const eventsUiUrl = config.EVENTSUI_URL;
export const scholarshipsUiUrl = config.SCHOLARSHIPSUI_URL;
export const superMatchUiUrl = config.SUPERMATCHUI_URL;
export const navianceScholarshipUiUrl = config.NAVIANCESCHOLARSHIPSUI_URL;
export const surveysNextUiUrl = config.NAVIANCESURVEYSUI_URL;
export const activeMatchUiUrl = config.ACTIVEMATCHUI_URL;
export const readinessIndicatorsUiUrl = config.READINESS_INDICATORS_UI_URL;
export const headed2UiUrl = config.HEADED2_UI_URL;
export const headed2TokenExchangeServiceUrl = config.HEADED2_TOKEN_EXCHANGE_URL;
export const headed2BaseURL = config.HEADED2_API_DOMAIN;
export const headed2AppUrl = config.HEADED2_APP_BASE_URL;
export const myPathwaysUiUrl = config.MY_PATHWAYS_UI_URL as string;
export const ingestRawEventCtaApiBaseUrl = config.INGEST_RAW_EVENT_CTA_APP_BASE_URL;
export const portalApiHost = config.PORTAL_API_HOST;

export default {
  achieveworks: `${host}/achieve-works-assessments`,
  areasOfStudy: `${host}/areas-of-study/search?limit=${FETCH_MAX_RESULTS}`,
  auth0migrationlock: `${host}/auth0migration/lock`,
  portalApiHost,
  career: `${host}/career`,
  careerInterestProfiler: `${host}/career-interest-profiler`,
  careerKey: `${host}/career-key`,
  cluster: `${host}/career-cluster`,
  clusterFinder: `${host}/career-cluster-finder`,
  colleges: `${host}/college`,
  collegesEventsNext: `${host}/collegeevent`,
  collegesProfilesNext: `${host}/collegeprofile`, // for premiumHEM we load data via proxy that talks to Elastic Search via match service
  collegeVisits: `${host}/college-visit`,
  courseComments: `${host}/course-plans-student-comments`,
  coursePlans: `${host}/course-plans`,
  courseRecords: `${host}/course-records`,
  courseThoughts: `${host}/course-plans-student-thoughts`,
  courses: `${host}/courses`,
  documents: `${host}/documents`,
  highschool: `${host}/highschool`,
  interestingCourses: `${host}/interesting-courses`,
  host,
  hubsAppUrl: HUBS_APP_URL,
  journal: `${host}/journal`,
  journalTypes: `${host}/journal-types`,
  myPlanner: `${host}/success-planner`,
  religiousAffiliation: `${host}/religiousAffiliation`,
  roadTripNation: `${host}/road-trip-nation`,
  scholarships: `${host}/scholarships`,
  security: `${host}/security`,
  studentCoursePlans: `${host}/student-course-plans`,
  surveys: `${host}/surveys`,
  strengthsExplorer: `${host}/strengths-explorer`,
  territories: `${host}/territories`,
  users: `${host}/users`,
  match: `${host}/match`,
  superMatch: `${host}/supermatch`,
  activeMatchNext: `${host}/activematch`,
  scholarshipsMatch: `${host}/scholarshipsmatch`,
  testScores: `${host}/test-scores`,
  home: `${host}/home`,
  transcripts: `${host}/transcript`,
  commonApp: `${host}/common-app`,
  commonAppNext: `${host}/common-app-next`,
  teachersRecommendations: `${host}/teacher-recommendations`,
  enrichmentPrograms: `${host}/enrichment-programs`,
  youscience: `${host}/you-science`,
  audit: `${host}/audit`,
  studentReadinessReports: `${host}/template-builder`,
  translationService: `${host}/translation`,
  studentReadinessIndicators: `${host}/student-readiness-indicators`,
  headed2: `${host}/naviance-headed2`,
};
