import withGlobals from 'components/Globals';
import PageTemplate from 'components/PageTemplate';
import { careers2UiUrl, host as apiHost } from 'config/api';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ScriptLoader from 'react-script-loader-hoc';
import ApiClient from 'utils/ApiClient';
import session from '../../config/session';
import Icon from 'components/Icon';
import { connect } from 'react-redux';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getPermissions } from 'selectors/permissions';
import { FeatureFlags } from 'types/featureFlags';
import { HSPermissions } from 'types/permissions';

const getFetchUrl = (path: string) => `${apiHost}${path}`;

const apiClient = new ApiClient(getFetchUrl);

interface C2Props extends RouteComponentProps {
  scriptsLoaded: boolean;
  currentUser: {};
  featureFlags: FeatureFlags;
  permissions: HSPermissions;
}

const Careers2: React.FunctionComponent<C2Props> = ({
  history,
  scriptsLoaded,
  currentUser,
  featureFlags,
  permissions,
}) => {
  // The following hook is used to give Careers2 a way of forcing a re-render with hot reloading during development
  const [{ Careers2App }, updateC2App] = React.useState(
    {} as {
      Careers2App?: React.ComponentClass<any, any>;
    }
  );

  if (scriptsLoaded && !Careers2App) {
    updateC2App({ Careers2App: global.Careers2.App });
  }
  const { data, isValid } = session;
  const userSession = { userSession: data, isValid, currentUser };

  return scriptsLoaded && Careers2App ? (
    <PageTemplate title="Careers 2">
      <Careers2App
        apiClient={apiClient}
        userSession={userSession}
        fcuiRouterHistory={history}
        forceUpdate={updateC2App}
        icon={Icon}
        featureFlags={featureFlags}
        permissions={permissions}
      />
    </PageTemplate>
  ) : null;
};

interface WrapperProps extends C2Props {
  auth?: {
    currentUser: {};
  };
}

class Careers2Wrapper extends React.Component<WrapperProps> {
  render() {
    const user = this.props.auth ? this.props.auth.currentUser : {};
    return (
      <Careers2
        currentUser={user}
        history={this.props.history}
        location={this.props.location}
        match={this.props.match}
        scriptsLoaded={this.props.scriptsLoaded}
        featureFlags={this.props.featureFlags}
        permissions={this.props.permissions}
      />
    );
  }
}

export default withGlobals(
  connect((state) => ({
    auth: (state as any).auth,
    featureFlags: getFeatureFlags(state),
    permissions: getPermissions(state),
  }))(ScriptLoader(`${careers2UiUrl}?v=${Date.now()}`)(withRouter(Careers2Wrapper)))
);
