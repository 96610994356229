// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { isGhostingParentUser, getParent } from 'selectors/auth';
import s from './styles.scss';

type Props = {
  parent: Object,
  isGhostingParent: boolean,
  fullName: string,
};

@connect(
  (state) => ({
    isGhostingParent: isGhostingParentUser(state),
    parent: getParent(state),
    fullName: state.auth.fullName || '',
  }),
  null
)
export default class ViewAsStudentBanner extends React.Component<Props> {
  static defaultProps = {
    fullName: '',
    parent: {
      fullName: '',
    },
    isGhostingParent: false,
  };

  render() {
    const { isGhostingParent, parent, fullName } = this.props;
    const name = isGhostingParent ? parent.fullName : fullName;

    return (
      <div className={s.banner}>
        You are viewing Naviance Student as <b>{name || fullName}</b>
      </div>
    );
  }
}
