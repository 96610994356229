import * as React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedComponent } from 'react-redux';
import * as ReactRouter from 'react-router';
import * as ReactRouterDOM from 'react-router-dom';

const withGlobals = (
  WrappedComponent: ConnectedComponent<React.ComponentType, any> | React.ComponentType
) => {
  return class extends React.Component {
    constructor(props: any) {
      super(props);
      global.React = React;
      global.ReactDOM = ReactDOM;
      global.ReactRouter = ReactRouter;
      global.ReactRouterDOM = ReactRouterDOM;
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withGlobals;
